import React from "react";
import Background from "../../../../assets/images/headerbg.png";
import { Box, Container, Grid } from "@mui/material";
import LockerMenu from "../../LockerMenu";
import styled from "@emotion/styled";
import { DataImage, Paragraph } from "../../../Styles/style";
import Heading from "../../../../assets/images/headerheading.png";
import CustomButton from "../../CustomButton";
import HeaderDog from "../../../../assets/images/headerdg.png";

const BackgroundDiv = styled.div`
  background-image: url(${Background});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
`;
const GridBox = styled(Box)`
  background-color: rgba(250, 55, 171, 0.2);
`;
const DogGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 899px) {
    justify-content: center;
  }
`;
const Header = () => {
  return (
    <BackgroundDiv>
      <LockerMenu />
      <Container maxWidth="lg">
        <Grid container sx={{ padding: "50px 0% 3%" }}>
          <Grid item xs={12} md={6}>
            <DataImage mw="100%" src={Heading} />
            <GridBox>
              <Paragraph ta="left" pd="10px 10px">
                Welcome to MegaByte, where Byte meets his better half! Embrace
                the charms of Byte's delightful companion, MegaByte. It's not
                just a play on words; it's a journey into the heart of a digital
                romance, where the world of bytes gathers to launch another moon
                mission!
              </Paragraph>
              <Paragraph ta="left" pd="5px 10px">
                CA: 0x51b1038cbc26F4c83Df6E01d6b56d23095f5ee60
              </Paragraph>

              <CustomButton
                bg="#FA37AB"
                c="#000021"
                m="10px"
                p="5px 15px"
                name="Join Telegram"
                href="https://t.me/megabytetoken"
              />
              <CustomButton
                bg="#000021"
                c="#FA37AB"
                m="10px"
                p="5px 15px"
                href="https://twitter.com/megabytetoken"
                name="Join X(Twitter)"
              />
            </GridBox>
          </Grid>
          <DogGrid item xs={12} md={6}>
            <DataImage mw="60%" mw899="50%" src={HeaderDog} />
          </DogGrid>
        </Grid>
      </Container>
    </BackgroundDiv>
  );
};
export default Header;
