import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { DataImage, Heading, Paragraph } from "../../../Styles/style";
import styled from "@emotion/styled";
import aboutImage from "../../../../assets/images/aboutImage.png";
import elon from "../../../../assets/images/elon.png";
import grok from "../../../../assets/images/grok.png";
import SendIcon from "@mui/icons-material/Send";

const BackgroundDiv = styled.div`
  background-color: #fa37ab;
  min-height: 100vh;
`;
const AboutBox = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: center;
  max-width: 900px;
  margin: auto;
`;
const AboutGrid = styled(Box)`
  background-color: black;
  padding: 20px;
  max-width: 80%;
  margin: 20px auto;
`;
const TextINPUT = styled.textarea`
  background: #15151525;
  filter: brightness(5);
  padding: 10px 20px 0px;
  width: 100%;
  margin: 10px 0px;
  /* height: 100px; */
  border-radius: 20px;
  border: none !important;
  color: grey;
  :focus {
    outline: none !important;
    outline: none !important;
  }
`;
const About = () => {
  return (
    <BackgroundDiv id="about">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sx={{ padding: "60px 0px" }}>
            <Heading variant="h3">about megabyte</Heading>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{ display: "grid", placeItems: "center" }}
          >
            <Grid item xs={12} sm={10} md={7} sx={{ display: "flex",flexDirection:"column", alignItems:"start" }}>
              <AboutBox>
                <DataImage mw="35px" src={elon} />
                <Heading variant="h7">Elon Musk:</Heading>
                <Paragraph>
                  &nbsp; &nbsp;If your (Grok) dog byte had a wife what would the
                  name be?
                </Paragraph>
              </AboutBox>
              <AboutBox sx={{alignItems:"start !important",margin:"20px 0 !important"}}>
              <DataImage mw="35px" src={grok} />
                <Heading variant="h7">Grok:</Heading>
                <Paragraph
                  pd="0px 2px"
                  ta="left"
                  //    mw='600px'
                >
                  {/* <span style={{fontSize: '20px'}}>Elon Musk:</span> */}
                  &nbsp; &nbsp;If my dog, Byte, had a wife, I'd name her
                  "Megabyte" or "Meg" for short. it's a cute play on words and a
                  nod to the world of technology.
                </Paragraph>
              </AboutBox>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ display: "grid", placeItems: "center" }}>
            <DataImage mw="70%" mw899="100%" src={aboutImage} />
          </Grid>
        </Grid>
      </Container>
    </BackgroundDiv>
  );
};
export default About;
