import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";

const ButtonStyle = styled(Button)`
    background-color: ${props=>props.bg? props.bg : ''};
    color: ${props=>props.c? props.c : ''};
    padding: ${props=>props.p? props.p : ''};
    margin: ${props=>props.m? props.m : ''};
    text-transform: none;
    :hover{
        background-color: ${props=>props.bghover? props.bghover : ''};
        color: ${props=>props.chover? props.chover : ''};
    }
`
const CustomButton = (props) => {
    const { name, href } = props;
    return(
        <ButtonStyle {...props} href={href}>{name}</ButtonStyle>
    )
}
export default CustomButton;