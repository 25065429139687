import { Container } from '@mui/material';
import React from 'react'
import DesktopMenu from './component/DesktopMenu';
import MobileMenu from './component/MobileMenu';

const LockerMenu = (props) => {
    const menuList = [ // You can pass 4 perameters for menu title, link, target and customClass
        {
        title: "Home",
        link: "#home",
        },
        {
        title: "About",
        link: "#about",
        },
        {
        title: "Tokenomics",
        link: "#tokenomics",
        },
        {
        title: "Buy Now",
        link: "https://app.uniswap.org/#/swap?outputCurrency=0x51b1038cbc26F4c83Df6E01d6b56d23095f5ee60&chain=ethereum",
        customClass: "",
        target: "_blank"
        },
    ];
  return (
    <Container>
        <DesktopMenu menuList={menuList} />
        <MobileMenu menuList={menuList} />
    </Container>
  )
}

export default LockerMenu