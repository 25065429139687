import { Container, Grid, Link } from "@mui/material";
import React, { useState } from "react";
import Dog from '../../../../assets/images/headerdg.png';
import etherscan from '../../../../assets/images/etherscan.png';
import dextools from '../../../../assets/images/dextools.png';
import uniswap from '../../../../assets/images/uniswap.png';
import { DataImage, Paragraph } from "../../../Styles/style";
import Cards from "../../Cards";
import styled from "@emotion/styled";
import LeftArrow from '../../../../assets/images/leftarrrow.png';
import rightArrow from '../../../../assets/images/rightarrow.png';
import headerheading from '../../../../assets/images/headerheading.png';
import Marquee from "react-fast-marquee";

const CardsGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ArrowGrid = styled(Grid)`
    display: flex;
    justify-content: ${props=>props.jc? props.jc : ""};
    align-items: ${props=>props.ai? props.ai : ""};
    @media (max-width: 600px){
        justify-content: center;
    }
`

const Tokenomics = () => {
    const cardsData = [
        { title: '0% Tax', tag: 'Fully Decentralized and community driven memecoin.' },
        { title: 'Name', tag: 'MEGABYTE' },
        { title: 'Ticker', tag: '$MEG' },
    ];

    const [currentCardIndex, setCurrentCardIndex] = useState(0);

    const handleArrowClick = (direction) => {
        if (direction === 'left') {
            setCurrentCardIndex((prevIndex) => (prevIndex - 1 + cardsData.length) % cardsData.length);
        } else {
            setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cardsData.length);
        }
    };

    return (
        <div id="tokenomics">
        <div style={{minHeight: '100vh'}}>
            <Marquee  style={{
                background:"#000037",
                padding:"20px 0"
            }}>
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
              </Marquee>
            <Container maxWidth='lg' >
                <Grid container>
                    <Grid item xs={12} textAlign='center' sx={{padding: '50px 0px'}}>
                        <DataImage mw='20%' mw899="50%"src={Dog} />
                    </Grid>
                    <Grid container sx={{padding: '20px 0px'}}>
                        <ArrowGrid px={1} jc='end' item xs={3}  sm={3} md={3} onClick={() => handleArrowClick('left')} style={{ cursor: 'pointer' }}>
                            <DataImage mw='40%' mw899='100%' src={LeftArrow}/>
                        </ArrowGrid>
                            <Cards
                               title={cardsData[currentCardIndex].title}
                               tag={cardsData[currentCardIndex].tag}
                            />
                        <ArrowGrid px={1} jc='start' item xs={3}  sm={3} md={3} onClick={() => handleArrowClick('right')} style={{ cursor: 'pointer' }}>
                            <DataImage mw='40%' mw899='100%' src={rightArrow}/>
                        </ArrowGrid>
                    </Grid>
                    <Grid item xs={12} textAlign='center' sx={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:{xs:"column",sm:"row"},padding: '50px 0px',gap:"20px"}}>
                        <Link target="blank" href="https://www.dextools.io/app/en/ether/pair-explorer/0x51b1038cbc26F4c83Df6E01d6b56d23095f5ee60"><DataImage mw='120px' src={dextools} /></Link>
                        <Link target="blank" href="https://app.uniswap.org/#/swap?outputCurrency=0x51b1038cbc26F4c83Df6E01d6b56d23095f5ee60&chain=ethereum"><DataImage mw='150px' src={uniswap} /></Link>
                        <Link target="blank" href="https://etherscan.io/token/0x51b1038cbc26F4c83Df6E01d6b56d23095f5ee60"><DataImage mw='150px' src={etherscan} /></Link>
                    </Grid>
                </Grid>
            </Container>
            <Marquee style={{
                background:"#000037",
                padding:"20px 0"
            }}>
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
                <DataImage mw="80%" mw599="60%" src={headerheading} />
              </Marquee>
        </div>
        <div style={{padding:"30px 0 10px"}}>
            <Paragraph>All Rights Reserved</Paragraph>
        </div>
        </div>
    );
};

export default Tokenomics;
