import React from 'react'
import LockerMenu from '../../Components/LockerMenu'
import { PageWrapper } from '../../Styles/style'
import Header from '../../Components/Sections/Header'
import About from '../../Components/Sections/About'
import Tokenomics from '../../Components/Sections/Tokenomics'

const MainPage = (props) => {
  return (
    <PageWrapper>
        <Header/>
        <About/>
        <Tokenomics/>
    </PageWrapper>
  )
}

export default MainPage