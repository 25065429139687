import React from "react";
import { Heading, Paragraph } from "../../Styles/style";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const CardDiv = styled(Grid)`
    background-color: #000037;
    min-height: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 30px;
    max-width: 400px;
`
const Cards = (props) => {
    const { title, tag } = props;
    return(
        <CardDiv item xs={6} sm={6} md={6 }>
            <Heading variant="h5" cl="#FA37AB">{title}</Heading>
            <Paragraph>{tag}</Paragraph>
        </CardDiv>
    )
}
export default Cards;