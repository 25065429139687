import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const PageWrapper = styled.div`
    background-color: ${ (props) => props.theme.pageColor};
    min-height: 100vh;
`
const DataImage = styled.img`
    width: 100%;
    height: auto;
    max-width: ${(props) => (props.mw ? props.mw : "")};
    margin: ${(props) => (props.m ? props.m : "0px")};
    @media (max-width: 899px) {
        max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
    }
`
const Paragraph = styled(Typography)`
    color: ${props=>props.cl? props.cl : "#fff"};
    text-align: ${props=>props.ta? props.ta : 'center'};
    font-size: ${props=>props.fs? props.fs : '16px'};
    padding: ${props=>props.pd? props.pd : ''};
    font-family: PoppinsR;
    max-width: ${props=>props.mw? props.mw : ''};;
    @media (max-width: 600px){
        font-size: ${props=>props.fs? props.fs : '12px'};
    }
`
const Heading = styled(Typography)`
    color: ${props=>props.cl? props.cl : "#fff"};
    text-align: ${props=>props.ta? props.ta : 'center'};
    padding: ${props=>props.pd? props.pd : ''};
    font-family: PoppinsB;
    text-transform: uppercase;
    font-style: italic;
    @media (max-width: 600px){
    }
`
export { PageWrapper, DataImage, Paragraph, Heading }